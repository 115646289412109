<template>
  <div class="admitStuStatusConfirm">
    <van-notice-bar
      left-icon="info-o"
      text="以下不可更改的信息若有误请联系学校老师"
    />
    <van-tabs v-model="activeName" animated swipeable>
      <van-tab title="基本信息" name="basic">
        <div class="infoModule-cont">
          <van-form @submit="submitEvent" @failed="failedEvent">
            <van-field
              v-model="studentInfo.name"
              name="真实姓名"
              label="真实姓名"
              placeholder="真实姓名"
              disabled
            />
            <van-field
              v-model="$common.sexList[studentInfo.sex]"
              name="性别"
              label="性别"
              placeholder="性别"
              disabled
            />
            <van-field
              required
              readonly
              clickable
              name="picker"
              :value="studentInfo.nation"
              label="民族"
              placeholder="民族"
              @click="toastEvent('nation')"
              :rules="[{ required: true, message: '请选择' }]"
              :disabled="firstDisabled('nation')"
            />
            <van-field
              required
              readonly
              clickable
              name="picker"
              :value="studentInfo.politicsStatus"
              label="政治面貌"
              placeholder="政治面貌"
              @click="toastEvent('politicsStatus')"
              :rules="[{ required: true, message: '请选择' }]"
              :disabled="firstDisabled('politicsStatus')"
            />
            <van-field
              v-model="studentInfo.cardId"
              name="证件号"
              label="证件号"
              placeholder="证件号"
              disabled
            />
            <!-- <van-field
              required
              v-model="studentInfo.source"
              name="学生来源"
              label="学生来源"
              placeholder="学生来源"
              maxlength="40"
              :rules="[{ required: true, message: '请输入' }]"
              :disabled="firstDisabled('source')"
            /> -->
            <van-field
              required
              v-model="studentInfo.unit"
              name="工作单位"
              label="工作单位"
              placeholder="工作单位"
              maxlength="40"
              :rules="[{ required: true, message: '请输入' }]"
              :disabled="firstDisabled('unit')"
            />
            <van-field
              required
              v-model="studentInfo.address"
              name="通讯地址"
              label="通讯地址"
              placeholder="通讯地址"
              maxlength="40"
              :rules="[{ required: true, message: '请输入' }]"
              :disabled="firstDisabled('address')"
            />
            <van-field
              required
              v-model="studentInfo.email"
              name="电子邮箱"
              label="电子邮箱"
              placeholder="电子邮箱"
              maxlength="40"
              :rules="[{ required: true, message: '请输入' }]"
              :disabled="firstDisabled('email')"
            />
            <van-field
              required
              v-model="studentInfo.phone"
              name="手机号码"
              label="手机号码"
              placeholder="手机号码"
              maxlength="20"
              :rules="[{ required: true, message: '请输入' }]"
              v-if="studentInfo.studentConfirmPhoneFlag != 1"
              :disabled="firstDisabled('phone')"
            />
            <van-field
              required
              v-model="studentInfo.bindPhone"
              name="手机号码"
              label="手机号码"
              placeholder="手机号码"
              maxlength="20"
              :rules="[{ required: true, message: '请输入' }]"
              v-if="studentInfo.studentConfirmPhoneFlag == 1"
              :disabled="firstDisabled('bindPhone')"
            />
            <van-field
              required
              v-model="studentInfo.code"
              name="验证码"
              label="验证码"
              placeholder="验证码"
              maxlength="8"
              :rules="[{ required: true, message: '请输入' }]"
              :disabled="firstDisabled('code')"
              v-if="studentInfo.studentConfirmPhoneFlag == 1"
            >
              <template #button>
                <van-button size="small" round type="info" :disabled="isDisabled" @click="getVerify">{{btnText}}</van-button>
              </template>
            </van-field>
            <van-field
              required
              v-model="studentInfo.emergencyPhone"
              name="紧急联系人"
              label="紧急联系人"
              placeholder="紧急联系人"
              maxlength="20"
              :rules="[{ required: true, message: '请输入' }]"
              :disabled="firstDisabled('emergencyPhone')"
              />
            <van-button ref="formbtn" style="display: none;" round block type="info" native-type="submit">提交</van-button>
          </van-form>
        </div>
      </van-tab>
      <van-tab title="学籍信息" name="stuStatus">
        <div class="infoModule-cont">
          <van-form disabled>
            <van-field
              v-model="studentInfo.examNum"
              name="考生号"
              label="考生号"
              placeholder="考生号"
            />
            <van-field
              v-model="studentInfo.admissionBatch"
              name="批次"
              label="批次"
              placeholder="批次"
            />
            <van-field
              v-model="studentInfo.eduCategoryName"
              name="层次"
              label="层次"
              placeholder="层次"
            />
            <van-field
              v-model="studentInfo.majorName"
              name="专业"
              label="专业"
              placeholder="专业"
            />
            <van-field
              v-model="studentInfo.stationName"
              name="教学点"
              label="教学点"
              placeholder="教学点"
            />
            <van-field
              v-model="studentInfo.studentNum"
              name="学号"
              label="学号"
              placeholder="学号"
            />
            <van-field
              v-model="$common.stuConfirmStatus[studentInfo.studentStatus]"
              name="学籍状态"
              label="学籍状态"
              placeholder="学籍状态"
            />
            <van-field
              v-model="admissionTime"
              name="入学时间"
              label="入学时间"
              placeholder="入学时间"
            />
            <van-field
              v-model="studentInfo.learningSystem"
              name="学制"
              label="学制"
              placeholder="学制"
            />
            <van-field
              v-model="studentInfo.tenantName"
              name="毕业院校"
              label="毕业院校"
              placeholder="毕业院校"
            />
            <van-field
              v-model="frontGraduationTime"
              name="前置毕业时间"
              label="前置毕业时间"
              placeholder="前置毕业时间"
            />
            <van-field
              v-model="$common.getRegistrationStatus[studentInfo.registrationStatus]"
              name="注册状态"
              label="注册状态"
              placeholder="注册状态"
            />
            <van-field
              v-model="graduationTime"
              name="毕业时间"
              label="毕业时间"
              placeholder="毕业时间"
            />
          </van-form>
        </div>
      </van-tab>
    </van-tabs>
    <div class="info-btn-area">
      <van-button round type="default" @click="goBack" >返回</van-button>
      <van-button round type="info" @click="enterEvent">确定</van-button>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- 上传选择 -->
    <van-action-sheet v-model="showUploadWay" :actions="actions" cancel-text="取消" close-on-click-action @select="onSelect" />
  </div>
</template>

<script>
import uploadAli from '@/api/uploadAli.js'
import { Toast } from 'vant'
export default {
  name: 'admitStuStatusConfirm',
  data() {
    return {
      activeName: 'basic',
      type: null,
      formInline: {
        address: null,
        code: null,
        email: null,
        nation: null,
        phone: null,
        bindPhone: null,
        politicsStatus: null,
        unit: null,
        emergencyPhone: null,
        // source: null
      },
      studentInfo: {},
      showPicker: false,
      columns: [],
      pickname: null,
      isDisabled: false,
      btnText: '获取验证码',
      arrDara: [],
      exampleUpIdx: null,
      showUploadWay: false,
      actions: [
        { name: '拍照', value: 'video' },
        { name: '文件上传', value: 'image' }
      ],
      admissionTime: null, // 入学时间
      frontGraduationTime: null, // 前置毕业时间
      graduationTime: null, // 毕业时间
    }
  },
  watch: {
    '$store.state.isGetToken'(val) {
      if(val && this.$route.name == 'admitStuStatusConfirm') {
        this.type = this.$store.state.deviceType
        this.getUserData()
      }
    }
  },
  methods: {
    getUserData() {
      this.$request.admitStuInfoDetail().then(res => {
        if(res.data.code == 0) {
          this.studentInfo = res.data.data
          this.getValKey()
          this.formatter()
        }
      }).catch(error => {
        this.Error(error.response.data.msg)
      })
    },
    // stuInfoCollect() {
    //   this.$request.stuInfoCollect().then(res => {
    //     if (res.data.code == 0) {
    //       if (res.data.data.showFlag) {
    //         res.data.data.items.forEach(item => {
    //           item.urls = item.urls ? item.urls : []
    //         });
    //       }
    //       this.stuCollectInfo = res.data.data
    //     }
    //   }).catch(error => {
    //     this.Error(error.response.data.msg)
    //   })
    // },
    // 初次渲染的表单元素，有值的禁用处理
    getValKey() {
      if(this.arrDara.length != 0) return;
      for(let key in this.formInline) {
        this.formInline[key] = this.studentInfo[key]
      }
      for(let key in this.formInline) {
        if(this.formInline[key]) this.arrDara.push(key)
      }
    },
    firstDisabled(key) {
      if (this.arrDara.includes(key)) {
        return true
      } else {
        return false
      }
    },
    toastEvent(name) {
      if(this.firstDisabled(name)) return;
      this.pickname = name
      let arr = []
      switch(name) {
        case 'nation':
          arr = this.$common.nationData.map(item => item.name)
          this.columns = arr
          break
        case 'politicsStatus':
          arr = Object.values(this.$common.politicsData)
          this.columns = arr
          break
      }
      this.showPicker = !this.showPicker
    },
    onConfirm(value) {
      // this.value = value
      this.studentInfo[this.pickname] = value
      this.showPicker = false
    },
    // 获取手机号验证码
    getVerify() {
      if(!this.studentInfo.phone && this.studentInfo.studentConfirmPhoneFlag != 1) {
        this.Error('请输入手机号码')
        return
      }
      if(!this.studentInfo.bindPhone && this.studentInfo.studentConfirmPhoneFlag == 1) {
        this.Error('请输入手机号码')
        return
      }
      let phoneSend = this.studentInfo.studentConfirmPhoneFlag == 1 ? this.studentInfo.bindPhone : this.studentInfo.phone
      if(!this.$way.regPhoneNew(phoneSend)) {
        this.Error('手机号码格式有误')
        return
      }
      this.$request.sendMsg(phoneSend).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data || '发送成功')
          this.time()
        }
      })
    },
    // 倒计时
    time() {
      var wait = 60;
      this.isDisabled = true;
      var t = setInterval(() => {
        this.btnText = `请稍候(${wait})`;
        wait--;
        if (wait == -1) {
          clearInterval(t);
          this.btnText = "获取验证码";
          this.isDisabled = false;
          wait = 60;
        }
      }, 1000);
    },
    goBack() {
      if(this.type == 1)
        window.webkit.messageHandlers.getData.postMessage('close()')
      else if(this.type == 2)
        window.Android.Close()
    },
    enterEvent() {
      this.$refs.formbtn.click()
    },
    // form表单检验不通过时触发
    failedEvent(errorInfo) {
      // console.log('failed', errorInfo)
      Toast.fail('基本信息有必填项未填')
    },
    submitEvent() {
      for(let key in this.formInline) {
        this.formInline[key] = this.studentInfo[key]
      }
      console.log(this.formInline,'formInline')
      let objForm = JSON.parse(JSON.stringify(this.formInline))
      if(this.studentInfo.studentConfirmPhoneFlag == 1) objForm.phone = objForm.bindPhone
      delete objForm.bindPhone
      this.$request.admitStuInfoConfirm(objForm).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || '提交成功')
          this.goBack()
        } else {
          this.Error(res.data.msg)
        }
      }).catch(error => {
        this.Error(error.response.data.msg)
      })
    },
    handleUploadClick(item, index) {
      if (this.studentInfo.confirmStatus == 2) return;
      // this.example = item.id
      this.exampleUpIdx = index
      this.showUploadWay = !this.showUploadWay
    },
    // 上传方式选择
    onSelect(item) {
      if (item.value == 'video') {
        this.$refs.videoDom.click()
      } else if (item.value == 'image') {
        this.$refs.fileDom.click()
      }
    },
    formatter() {
      let admissionTime = this.$way.timestampReturnDate(this.studentInfo.admissionTime)
      let frontGraduationTime = this.$way.timestampReturnDate(this.studentInfo.frontGraduationTime)
      let graduationTime = this.$way.timestampReturnDate(this.studentInfo.graduationTime)
      this.admissionTime = admissionTime
      this.frontGraduationTime = frontGraduationTime
      this.graduationTime = graduationTime
    },
  },
}
</script>

<style lang='scss'>
  .admitStuStatusConfirm {
    height: 100vh;
    overflow: scroll;
    // padding: 0 24px;
    background-color: #F9F9F9;
    .infoModule-cont {
      padding: 16px 24px;
      box-sizing: border-box;
      .audit-area {
        margin-bottom: 16px;
      }
      .van-form {
        border-radius: 10px;
        overflow: hidden;
      }
      .collect-item {
        background-color: #fff;
        box-sizing: border-box;
        padding: 16px 16px 24px;
        border-bottom: 1px solid #EFEFEF;
        .ci-title {
          font-size: 14px;
          color: #363840;
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          & > span:nth-of-type(1) {
            width: 220px;
          }
          & > span:nth-of-type(2) {
            color: #1A7EE2;
            display: flex;
            align-items: center;
            width: 80px;
            margin-left: 10px;
            img {
              width: 14px;
              height: 14px;
              margin-right: 4px;
            }
          }
        }
        .fc-area {
          display: flex;
          justify-content: space-around;
        }
        .ke-show {
          position: relative;
          width: 134px;
          height: 84px;
        }
        .addImg {
          width: 134px;
          height: 84px;
          border-radius: 4px;
          position: relative;
          cursor: pointer;
          & > img:nth-of-type(1) {
            width: 134px;
            height: 84px;
          }
          & > img:nth-of-type(2) {
            width: 32px;
            height: 32px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .img-ke {
          text-align: center;
        }
        .van-image {
          width: 134px;
          height: 84px;
          border-radius: 4px;
          overflow: hidden;
        }
        .zhez {
          position: absolute;
          width: 134px;
          height: 84px;
          border-radius: 4px;
          background-color: #33333388;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            margin: 0 10px;
          }
          .deleteI {
            position: inherit;
            right: 0px;
            top: 8px;
          }
          .downI {
            width: 30px;
            height: 30px;
          }
        }
        .notImg {
          width: 134px;
          height: 84px;
          text-align: center;
          background-color: #f9f9f9;
          word-break: break-all;
          display: flex;
          flex-direction: column;
          align-items: center;
          // justify-content: center;
          // margin: 0 auto;
          border-radius: 4px;
          font-size: 8px;
          box-sizing: border-box;
          padding: 4px 6px;
          img {
            width: 20px;
            height: 20px;
            margin-bottom: 6px;
          }
        }
        .ci-dec {
          margin-top: 10px;
          font-size: 18px;
        }
      }
      .collect-tip {
        padding: 26px 16px;
        & > p {
          color: #666666;
          font-size: 14px;
        }
        & > p:nth-of-type(1) {
          color: #333333;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
    .collect-cont {
      background-color: #fff;
    }
    .info-btn-area {
      box-sizing: border-box;
      padding: 45px 24px 20px;
      text-align: center;
      .van-button {
        margin: 0 10px;
      }
    }
  }
</style>